<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useIsFetching } from '@tanstack/vue-query';
import type { RouteLocationRaw } from '#vue-router';

const user = useSupabaseUser();

interface NavigationItem {
	label: string;
	link: RouteLocationRaw;
}

const { t } = useI18n();

const navigation = computed<NavigationItem[]>(() => {
	return [
		{
			label: t('topbar.links.dashboard'),
			link: { name: 'MerchantDashboard' },
		},
		{
			label: t('topbar.links.campaigns'),
			link: { name: 'MerchantCampaigns' },
		},
		{
			label: t('topbar.links.submissions'),
			link: { name: 'MerchantSubmissions' },
		},
		{
			label: t('topbar.links.payouts'),
			link: { name: 'MerchantPayouts' },
		},
	];
});

const hasNavigation = computed(() => navigation.value.length > 0);

const { data: merchantsData } = useMerchantsQuery();
const merchants = useArray(merchantsData);

const hasAdminAccess = computed(() => merchants.value.some((m) => m.isAdmin));

const userNavigation = computed<NavigationItem[]>(() => {
	return [
		...insertIf<NavigationItem>(hasAdminAccess.value, {
			label: t('topbar.userMenu.links.adminDashboard'),
			link: { name: 'AdminMerchantSelection' },
		}),
		{
			label: t('topbar.userMenu.links.account'),
			link: { name: 'account' },
		},
		{
			label: t('topbar.userMenu.links.logout'),
			link: { name: 'logout' },
		},
	];
});

const isFetching = useIsFetching();

const { data: creatorData } = useCreatorQuery();

const avatarUrl = computed(() => creatorData.value?.avatar ?? user.value?.user_metadata.avatar_url);
</script>

<template>
	<Disclosure
		v-slot="{ open }"
		as="nav"
		class="border-b border-gray-200 bg-white"
	>
		<UContainer>
			<div class="flex h-16 justify-between">
				<div class="flex">
					<div class="flex flex-shrink-0 items-center">
						<img
							class="block h-8 w-auto lg:hidden"
							src="/clickfire-flame.png"
							alt="Clickfire"
						>
						<img
							class="hidden h-8 w-auto lg:block"
							src="/clickfire-flame.png"
							alt="Clickfire"
						>
					</div>
					<div
						v-if="hasNavigation"
						class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
					>
						<NuxtLink
							v-for="item in navigation"
							:key="item.label"
							v-slot="{ isActive }"
							:to="item.link"
							class="inline-flex items-center"
							aria-current="page"
						>
							<span
								:class="[
									'h-full inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
									{
										'border-amber-500 text-gray-900': isActive,
										'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': !isActive,
									}]"
							>
								{{ item.label }}
							</span>
						</NuxtLink>
					</div>
				</div>
				<div class="flex sm:ml-6 gap-1.5">
					<WidgetLanguageSwitcher class="self-center relative z-[10000000]" />
					<ClientOnly>
						<div class="hidden sm:flex sm:items-center">
							<Menu
								as="div"
								class="relative ml-3"
							>
								<div>
									<MenuButton class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">
										<span class="absolute -inset-1.5" />
										<span class="sr-only">{{ $t('accessibility.topbar.openUserMenu') }}</span>
										<UAvatar
											:src="avatarUrl"
											:alt="user?.email"
											class="[&>*]:object-cover"
										/>
									</MenuButton>
								</div>
								<Transition
									enter-active-class="transition ease-out duration-200"
									enter-from-class="transform opacity-0 scale-95"
									enter-to-class="transform opacity-100 scale-100"
									leave-active-class="transition ease-in duration-75"
									leave-from-class="transform opacity-100 scale-100"
									leave-to-class="transform opacity-0 scale-95"
								>
									<MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										<MenuItem
											v-for="item in userNavigation"
											:key="item.label"
											v-slot="{ active, close }"
										>
											<div>
												<NuxtLink
													v-slot="{ href, navigate }"
													custom
													:to="item.link"
												>
													<a
														:href="href"
														:class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
														@click.prevent="() => { close(); navigate() }"
													>
														{{ item.label }}
													</a>
												</NuxtLink>
											</div>
										</MenuItem>
									</MenuItems>
								</Transition>
							</Menu>
						</div>
					</ClientOnly>
					<div class="-mr-2 flex items-center sm:hidden">
						<!-- Mobile menu button -->
						<DisclosureButton class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">
							<span class="absolute -inset-0.5" />
							<span class="sr-only">{{ $t('accessibility.topbar.openMainMenu') }}</span>
							<Bars3Icon
								v-if="!open"
								class="block h-6 w-6"
								aria-hidden="true"
							/>
							<XMarkIcon
								v-else
								class="block h-6 w-6"
								aria-hidden="true"
							/>
						</DisclosureButton>
					</div>
				</div>
			</div>
		</UContainer>

		<UProgress
			v-show="isFetching"
			size="xs"
			:ui="{ progress: { track: '[&::-webkit-progress-bar]:bg-transparent' } }"
			class="absolute -mt-px"
		/>

		<DisclosurePanel
			v-slot="{ close }"
			class="sm:hidden absolute bg-white w-full z-50 top-16 h-full"
		>
			<div
				v-if="hasNavigation"
				class="space-y-1 pb-3 pt-2"
			>
				<NuxtLink
					v-for="item in navigation"
					:key="item.label"
					v-slot="{ isActive }"
					:to="item.link"
					aria-current="page"
					class="w-full block"
					@click="close"
				>
					<DisclosureButton
						as="div"
						:class="[
							'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
							{
								'border-amber-500 bg-amber-50 text-amber-700': isActive,
								'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800': !isActive,
							}]"
					>
						{{ item.label }}
					</DisclosureButton>
				</NuxtLink>
			</div>
			<div
				v-if="user"
				class="border-t border-gray-200 pb-3 pt-4"
			>
				<div class="flex items-center px-4">
					<div class="flex-shrink-0">
						<UAvatar
							:src="avatarUrl"
							:alt="user.email"
							class="[&>*]:object-cover"
						/>
					</div>
					<div class="ml-3">
						<div class="text-base font-medium text-gray-800">
							{{ user.user_metadata.full_name }}
						</div>
						<div class="text-sm font-medium text-gray-500">
							{{ user.email }}
						</div>
					</div>
				</div>
				<div class="mt-3 space-y-1">
					<NuxtLink
						v-for="item in userNavigation"
						:key="item.label"
						:to="item.link"
						class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
						@click="close"
					>
						<DisclosureButton as="div">
							{{ item.label }}
						</DisclosureButton>
					</NuxtLink>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>
